/**
 * 时间戳转时间
 * @param timestamp  时间戳
 * @param type  类型 1-（年-月-日 时:分:秒） 2-（年-月-日）
 */
export function timestampToTime(timestamp, type = 1) {
  let date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  let h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  let m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  if (type == 2) {
    return (Y + M + D).trim();
  }
  return Y + M + D + h + m + s;
}

/**
 * 处理终端MAC
 * @param str  待处理终端
 */
const string_to_mac = (str) => {
  let result = "";
  for (let i = 0, len = str.length; i < len; i++) {
    result += str[i];
    if (i != len - 1 && i % 2 == 1) result += ":";
  }
  return result;
};

/**
 * 批量处理终端MAC
 * @param macStr  待处理终端集
 */
export function BatchProcessMac(macStr) {
  macStr = macStr
    .replace(/\r\n/g, ",")
    .replace(/\r/g, ",")
    .replace(/\n/g, ",")
    .replace(/，/g, ",")
    .replace(/,+/g, ",");
  let mac_data = macStr.split(",");
  let mac_arr = mac_data.map(function (item) {
    let mac_info = item.trim();
    if (!/:/.test(mac_info)) {
      mac_info = string_to_mac(mac_info);
    }
    return mac_info;
  });
  let err_mac = [];
  mac_arr.forEach(function (item, index) {
    if (
      item.length !== 17 ||
      !/([A-Fa-f0-9]{2}:){5}[A-Fa-f0-9]{2}/.test(item)
    ) {
      err_mac.push(mac_data[index]);
      return false;
    }
  });
  if (err_mac.length > 0) {
    return { result: false, info: err_mac.join(",") };
  }
  mac_arr = [...new Set(mac_arr)];
  return { result: true, info: mac_arr.join(",") };
}

/**
 * 数字格式化（10000->10,000）
 * @param num  待处理数字
 */
export function formatter_number(num) {
  const reg = /^\d*$/;
  if (!reg.test(num)) {
    return false;
  } else {
    return String(num).replace(/\d{1,3}(?=(\d{3})+(\.|$))/g, "$&,");
  }
}

/**
 * 处理终端MAC
 * @param str  待处理终端
 */
export function stringToMac(str) {
  let result = "";
  for (let i = 0, len = str.length; i < len; i++) {
    result += str[i];
    if (i != len - 1 && i % 2 == 1) result += ":";
  }
  return result;
}
